@use '../utils/' as *;
@use 'reset' as *;

html,
body {
  color: $color-slate;
  font-family: $body-font-family;
  font-size: rem($html-font-size);
  white-space: pre-wrap;
}

.ReactModal__Content--after-open:focus-visible {
  outline: none;
}

.grecaptcha-badge {
  z-index: 1;
  visibility: hidden;
}

.rc-anchor-error-msg-container {
  display: block;
  font-size: rem(10);
  padding: rem(5);
}

/* clears search input 'X' */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
